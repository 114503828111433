import UAParser from 'ua-parser-js';
import store, { emptyCart } from '@/store';
import { withAuth } from '@/auth';
import odooClient, { withLocale } from '@/odooClient';
import cloneDeep from 'lodash.clonedeep';
import deepMerge from 'lodash.merge';

export default (() => ({
  /**
   * Add or update line in cart.
   *
   * @param {Number} lineId
   * @param {String} type
   * @param {Object} route
   * @param {Object} order
   * @param {Array} attachments
   * @param {Object} fields
   *
   * @return {Object}
   */
  async upsert(
    lineId,
    type,
    { name, params, query },
    order,
    attachments = [],
    {
      product = {},
      image,
      fileWithTags,
      file,
      tagScale,
    } = {},
  ) {
    const { id, display_name: displayName, full_name_translated: fullName } = product;
    const uuid = crypto.randomUUID();
    const data = {
      id: lineId,
      type,
      order,
      attachments,
      fields: {
        product: { id, display_name: displayName, full_name_translated: fullName },
        image,
        fileWithTags,
        file,
        tagScale,
      },
      route: { name, params, query },
      verified: true,
    };

    const existing = store.cart.lines.find((l) => l.id === lineId);
    if (existing) {
      Object.assign(existing, data, {
        uuid: existing.uuid || uuid,
      });
    } else {
      store.cart.lines.push({
        ...data,
        id: Math.floor(Math.random() * Date.now()),
        uuid,
      });
    }
  },
  /**
   * Get line by id.
   *
   * @param {Number} id
   *
   * @return {Object}
   */
  getLine(id) {
    return store.cart.lines.find((l) => l.id === id);
  },
  /**
   * Fetch order line.
   *
   * @param {Number} saleOrderId
   * @param {Object} context
   *
   * @return {Promise}
   */
  async fetchOrderLine(saleOrderId, context) {
    const args = await withAuth([
      'sale.order.line',
      'read',
      [saleOrderId],
      withLocale(context),
    ]);

    return odooClient.request('call_kw', {
      service: 'object',
      method: 'execute_kw',
      args,
    });
  },
  /**
   * Whether cart contains max OSA lines.
   *
   * @return {Boolean}
   */
  hasMaxOsa() {
    return store.cart.lines.filter((l) => this.isOsa(l.type)).length > 1;
  },
  /**
   * Wether given type is OSA.
   *
   * @param {String} type
   *
   * @return {Boolean}
   */
  isOsa(type) {
    const types = ['custom', 'brigante'];
    return types.includes(type);
  },
  /**
   * Empty's the cart.
   */
  empty() {
    store.cart = cloneDeep(emptyCart);
  },
  /**
   * Restore cart from existing order.
   *
   * @param {Object} cart
   * @param {Number} orderEditId
   */
  restore(cart, orderEditId = null) {
    store.cart = deepMerge({}, emptyCart, cart);
    store.cart.order_id = orderEditId;
  },
  /**
   * Restore cart from existing legacy order (< v4).
   * These orders don't have a cart
   *
   * @param {Object} order
   * @param {Number} orderId
   * @param {Number} orderEditId
   */
  restoreLegacy(order, orderId, orderEditId = null) {
    let route = {};
    const lineId = Math.floor(Math.random() * Date.now());
    this.empty();

    if (order.product_id) {
      route = {
        name: 'order',
        params: {
          variant: order.product_id,
        },
      };
    } else {
      route = {
        name: 'custom-order',
      };
    }

    this.upsert(
      lineId,
      order.product_id ? 'brigante' : 'custom',
      route,
      {
        id: orderId,
        ...order,
      },
      [],
      {
        product: {
          display_name: order.product,
        },
      },
    );

    store.cart.order_id = orderEditId;
    store.cart.initial_app_version = order.initial_app_version || '3.0.0';
    store.cart.app_version = order.app_version || '3.0.0';
    store.cart.client.salutation = order.salutation;
    store.cart.client.customer_name = order.customer_name;
    store.cart.client.mold_number = order.last_no;
  },
  /**
   * Serialize cart (and remove any attachments).
   *
   * @return {String}
   */
  serialize() {
    const lines = store.cart.lines.map((l) => ({
      ...l,
      attachments: undefined,
      verified: undefined,
      fields: {
        product: l.fields.product,
      },
    }));
    const parser = new UAParser();

    return JSON.stringify({
      cart: {
        ...store.cart,
        lines,
        app_version: process.env.PACKAGE_VERSION,
        initial_app_version: store.cart.initial_app_version || process.env.PACKAGE_VERSION,
        initial_device: store.cart.initial_device || parser.getResult(),
      },
    });
  },
}))();
