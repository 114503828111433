export default {
  general: {
    hello: 'Hallo',
    back: 'Terug',
    left: 'Links',
    right: 'Rechts',
    both: 'Beide',
    side: 'Zijkant',
    flank: 'Zijde',
    backside: 'Achterkant',
    height: 'Hoogte',
    depth: 'Diepstand',
    thickness: 'Dikte',
    standard: 'Standaard',
    position: 'Positie',
    material: 'Materiaal',
    material_type: 'Materiaal type',
    clothe: 'Bekleden',
    type: 'Type',
    search: 'Zoeken',
    cancel: 'Annuleren',
    send: 'Versturen',
    ok: 'Oke',
    continue_anyway: 'Toch doorgaan',
    update: 'Er is een update beschikbaar.',
    install_update: 'Updaten',
    user: 'Gebruiker',
    products: 'Producten',
    client_info: 'Klantgegevens',
    order: 'Bestellen',
    edit: 'Wijzigen',
    coming_soon: 'Binnenkort beschikbaar',
    add_file: 'Bestand toevoegen',
    salutation_mr: 'Dhr.',
    salutation_mrs: 'Mw.',
    terms_and_conditions: 'Algemene voorwaarden',
    product_terms: 'Productvoorwaarden',
    faq: 'FAQ',
    not_available: 'Tijdelijk niet leverbaar',
    not_available_legend: '* Tijdelijk niet leverbaar',
    updated_color: 'Kleur is aangepast',
  },
  error: {
    header_api: 'Er is een API fout opgetreden',
  },
  account: {
    customer_portal: 'Klantportaal',
    title: 'Account',
    login: 'Inloggen',
    signout: 'Uitloggen',
    username: 'Gebruikersnaam',
    password: 'Wachtwoord',
    forgotpassword: 'Wachtwoord vergeten',
    logged_in: 'Je bent ingelogd',
    error: {
      title: 'Onjuiste login gegevens',
      not_found: 'Ongeldige gebruikersnaam/wachtwoord combinatie',
    },
  },
  orders: {
    title: 'Order menu',
    synchronise: 'Synchroniseer orders',
    history: 'Order historie',
    archive: 'Gearchiveerde orders',
    custom: 'Custom',
    history_compatibility_header: 'Order niet compatibel',
    history_compatibility_message: 'Let op: deze order is niet compatibel met deze app versie. Controleer of de order correct is.',
    no_results: 'geen orders gevonden',
  },
  language: {
    title: 'Taal wijzigen',
    dutch: 'Nederlands',
    german: 'Deutsch',
    english: 'English',
  },
  form: {
    choose_option: 'Kies optie',
    not_available: 'Geen',
    input_placeholder: 'Typen...',
    type: 'Type',
    number: 'Nummer',
    errors_heading: 'Formulier is niet volledig',
    errors_text: 'Er zijn een aantal velden niet volledig ingevoerd.',
    success_heading: 'Formulier is geldig',
    success_text: 'Formulier is klaar om te versturen.',
    save: 'Opslaan',
    select_ok: 'OK',
    select_cancel: 'Annuleren',
    undeliverable: '{field} is niet leverbaar',
  },
  order: {
    shafts: 'Schachten',
    existing_model: 'Model als gehad',
    select_image: 'Kies een afbeelding',
    remove_shoe_image: 'Verwijder schoen afbeelding',
    color: 'Kleur',
    all: 'Alle',
    amount: 'Aantal',
    variant: 'Uitvoering',
    leather_no: 'Leer {no}',
    decoration: 'Decoratie',
    perforation: 'Perforatie',
    complexity_price: 'Duidelijke afbeelding in kleur leveren',
    embroidery: 'Borduurwerk',
    lasering: 'Laseren',
    lining: 'Voering',
    vamp_lining: 'Voorblad voering',
    offset: 'Afstand',
    heelpart_lining: 'Achtervoering',
    tongue_label: 'Tonglabel',
    lining_vamp_no_stitches: 'Voorbladvoering naadloos',
    antislip_heel: 'Anti-slip',
    liner_shoe: 'Voeringschoen',
    vamp_point: 'Inschotpunt',
    main_stitches: 'Hoofdstiksel',
    decoration_stitching: 'Sierstiksel',
    quarter_opening: 'Openstand',
    choose_quarter_opening: 'Kies openstand',
    stitching_yarn: 'Dikte',
    fastening: 'Sluiting {no}',
    upper_height: 'Hoogte schacht',
    shaft_height_incl_heel: 'Schachthoogte inclusief hakhoogte',
    leg_measurements: 'Beenmaten',
    padded_shaft: 'Polster schacht',
    polster: 'Polstering',
    polster_upper: 'Hielpolster',
    polster_upper_left: 'Hielpolster links',
    polster_upper_right: 'Hielpolster rechts',
    polstering: 'Polster/foam',
    polstering_material: 'Materiaal polstering',
    polster_tongue: 'Polster tong',
    heelcollar_polster: 'Boordpolster',
    heelcollar_polster_type: 'Uitvoering boord',
    tongue: 'Tong',
    fixation_tongue: 'Tong bevestiging',
    supplement_type: 'Supplement type',
    supplement_stiffening: 'Verstijving',
    supplement_thickness: 'Supplement dikte',
    detailed_supplement_thickness: 'Supplement dikte (detaillering)',
    supplement_retaining_wall_height: 'Hoogte keerwand',
    supplement_amenity_height: 'Hoogte voorziening',
    detail_drawing: 'Werktekening',
    thickness_left: 'Dikte links',
    thickness_right: 'Dikte rechts',
    thickness_front: 'Dikte voorzijde',
    bottoming: 'Onderwerk',
    manufacture_method: 'Maakwijze',
    manufacture_method_note: 'Let op, snellere levertijd Type A, indien gefreesd!',
    supplement_remark: 'Opmerkingen Supplementen',
    fillsole: 'Ruimtezool/vulzool',
    innersole: 'Binnenzool',
    forefoot_prosthesis: 'Voorvoet prothese (teen amputatie)',
    sole_stiffner: 'Zoolverstijving',
    nose_material: 'Neus versteviging',
    nose_length: 'Neus lengte',
    counter_stiffening: 'Tegenverstijving',
    heel_stiffener: 'Versteviging',
    heel_stiffener_variant: 'Versteviging uitvoering',
    heel_stiffener_height: 'Versteviging hoogte',
    last_annotation: 'Zoals aangetekend op leest',
    garnish: 'Bezetsels',
    shaft_up_to_and_including_pins: 'Schacht tot en met oppennen',
    shaft_up_to_supply_material: 'Onderwerk materiaal los meeleveren',
    modeling_extension: 'Modelleringsverlenging',
    boarding: 'Stootband',
    boarding_front: 'Voorzijde',
    boarding_back: 'Achterzijde',
    boarding_front_back: 'Voor + achterzijde',
    welt: 'Rand',
    all_welts: 'Alle randen',
    mid_sole: 'Tussenzool',
    no_foot_strike: 'let op: geen afwikkeling',
    heel_type: 'Hak type',
    heel_position: 'Hak positie',
    heel_position_supplement: 'Hoogte (mm) verwerken in supplement',
    heel_position_bottoming: 'Hoogte (mm) verwerken in onderwerk',
    heel_color: 'Hakkleur/materiaal',
    primary_color: 'Hoofdkleur/materiaal',
    secondary_color: 'Tweede kleur/materiaal',
    heel_shore: 'Shorewaarde',
    heel_height: 'Hakhoogte',
    heel_width: 'Hakfront breedte',
    copy_left: 'Kopieer van links',
    heel_rolling: 'Hak afronding',
    heel_buffer: 'Hakbuffering',
    heel_expansion: 'Hakverbreden/Onderschuren',
    medial: 'Mediaal',
    lateral: 'Lateraal',
    rolling: 'Afwikkeling',
    rolling_direction: 'Richting',
    rolling_side: 'Afwikkeling {side}',
    rolling_type: 'Afwikkeling type',
    rolling_width: 'Afwikkellijn breedte',
    thickness_toe: 'Teendikte',
    thickness_ball: 'Baldikte',
    thickness_ball_lateral: 'Baldikte lateraal',
    thickness_ball_medial: 'Baldikte mediaal',
    thickness_heel: 'Hieldikte',
    rocker_wrapped_in_boarding: 'Afwikkeling ingepakt in stootband',
    sole: 'Zool',
    toplift: 'Achterlap',
    finishing: 'Afwerken',
    cover: 'Dekje',
    cover_material: 'Materiaal dekje',
    polster_material: 'Polsterlaag materiaal',
    lasting: 'Uitleesten',
    executor: 'Uitvoeren door',
    laces: 'Veters',
    finish_supplement: 'Supplement afwerken',
    place_cover: 'Polsterlaag plaatsen',
    padding_layer: 'Polsterlaag',
    shaft_remark: 'Opmerkingen Schachten',
    bottoming_remark: 'Opmerkingen Onderwerk',
    info: 'Order informatie',
    salutation: 'Aanhef',
    attachments: 'Bijlagen',
    last_no: 'Leestnummer',
    last_note: 'Leestnummer op leestkam aangeven',
    customer_name: 'Klant naam',
    confirm_leave_header: 'Wijzigingen annuleren?',
    confirm_leave_message: 'Weet u zeker dat u de orderpagina wilt verlaten? Eventuele ingevoerde data wordt niet opgeslagen.',
    sent_toast: 'Order is verzonden',
    number: 'Order',
    date: 'Datum',
    draft: 'Offerte',
    sale: 'Verkooporder',
    sent: 'Offerte verstuurd',
    done: 'Geblokkeerd',
    cancel: 'Geannuleerd',
    reorder: 'Opnieuw bestellen',
    edit: 'Bewerken',
    form: 'Formulier',
    velcro_extra_length: 'Extra lengte Klittenband',
    last: 'Leest',
    has_last: 'Heeft leest',
    supplements: 'Supplementen',
    foil_shoe: 'Folieschoenen',
    foil_shoe_height: 'Hoogte (incl. supplement)',
    foil_shoe_remark: 'Opmerkingen folieschoenen',
    copy_existing_last: 'Bestaande leest kopiëren',
    deliver: 'Aanleveren',
    deliver_note: 'Welke documentatie stuurt u op of voegt u toe?',
    cast: 'Cast',
    blueprint: 'Blauwdruk',
    scan: 'Lutracad',
    modeled_file: 'Gemodeleerde file aanleveren',
    scan_upload: 'Scan (eigen bestand)',
    last_type: 'Type leest',
    last_same_length: 'Leest even lang',
    encore: 'Toegift',
    encore_division: 'Leestlengte {side}',
    leg_position: 'Beenstand',
    fabric_edge: 'Stofrand',
    nose_shape: 'Neusvorm',
    last_remark: 'Opmerkingen Leesten',
    sizing: 'Maatvoering',
    leg_sizes: 'Omvangsmaten benen',
    height_measurement: 'Hoogte meting',
    circumferences: 'Omvangsmaten voeten',
    circumferences_no: 'Omvangsmaten voet {no}',
    foot_measurements: 'Correcties aan het bodemprofiel',
    position_toes: 'Positie tenen',
    height_toes: 'Hoogte',
    depth_toes: 'Diepstand',
    mtp_joints: 'MTP gewrichten',
    depth_position: 'MTP {no}',
    lateral_and_medial_gel: 'Lateraal en Mediaal geleng',
    medial_gel: 'Mediaal geleng',
    lateral_gel: 'Lateraal geleng',
    imprint_base_back: 'Opdruk achter Basis V',
    forefoot_support: 'Voorvoetsteun',
    forefoot_support_instructions: 'Aftekenen op supplement',
    t_pad: 'T-pelotte',
    transversal: 'Transversaalsteun',
    forefoot_and_heel_bone: 'Pronatie en Supinatie',
    pronation_front: 'Pronatie voorvoet',
    pronation_back: 'Pronatie achtervoet',
    suppination_front: 'Supinatie voorvoet',
    suppination_back: 'Supinatie achtervoet',
    lasts_supplements_foils: 'Leesten, supplementen en folies',
    finish: 'Afwerken',
    classics: 'Classics',
    flea: 'Vlos',
    orthotics: 'Orthesen',
    functional: 'Functioneel',
    heel_instep: 'Hiel / wreef',
    high_instep: 'Hoge wreef',
    low_instep: 'Lage wreef',
    ball: 'Bal',
    toe_jump: 'Teensprong',
    heel_lift: 'Hielheffing',
    heel_thickness: 'Dikte hiel',
    toe_no: 'Teen {no}',
    dimensions: 'Maatvoering',
    order_status: 'Orderstatus',
    confirm_osa_header: 'Maximale aantal OSA\'s in winkelwagen',
    confirm_osa_message: 'Wilt u de winkelwagen legen en een nieuwe bestelling starten?',
    confirm_osa_button_ok: 'Winkelwagen legen',
    leg_cover: 'Koker',
    trial_shoe: 'Proefschoen',
    bath_shoe: 'Badschoen',
    slipper: 'Pantoffel',
    basics: 'Basics',
    sock_shoe: 'Sokschoen',
    kind: 'Soort',
    changed_color_remark: '{color} is veranderd van kleur',
  },
  cart: {
    title: 'Winkelwagen',
    order: 'U bestelt voor',
    empty: 'Je winkelwagen is leeg',
    added_header: 'Product toegevoegd',
    added_subHeader: 'voor {customer}',
    added_text: 'Wilt u nog een product toevoegen voor deze klant?',
    added_continue_shopping: 'Extra product toevoegen',
    add_product: 'Product toevoegen',
    added_finish_order: 'Naar Winkelwagen',
    last_supplement_or_foil: 'Leest, supplement of folie',
    finish: 'Afwerken',
    custom: 'Custom',
    send_header: 'Bestelling versturen?',
    send_text: 'Let op: controleer goed of de order correct is ingevuld.',
    send_ok: 'Ja, versturen',
    submitting_order: 'Bezig met verwerken order',
    submitting_line: 'Verwerken van regel {index} van {total}',
    submitting_attachment: 'Verwerken van bijlage {index} van {total}',
    new_order: 'Nieuwe order plaatsen',
    confirm_empty_header: 'Weet u zeker dat u de klantgegevens en winkelwagen wilt legen?',
    confirm_empty_button_ok: 'Opnieuw beginnen',
    edit_client_info: 'Wijzigen',
    production_order: 'Versleep de producten om de productievolgorde aan te passen.\nDe order wordt van boven naar onder verwerkt.',
    verify_order: 'Check all order lines via the cogwheel icon.',
    verify_order_label: 'Orderregel verificatie',
  },
};
